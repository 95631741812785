import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import regeneratorRuntime from "/home/jenkins/agent/workspace/web-sunrise-prod/web-sunrise-landing-deploy-prod/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { IWidgetType, organizeProduct, organizeDevice, organizeProductWithProductRef } from "@vfit/consumer/data-access";
import { handleEnv } from "@vfit/shared/providers";
import { API, PAGES } from "@vfit/shared/data-access";
var MAX_NB_RETRY = 5;
var RETRY_DELAY_MS = 800;
var isDevLocal = handleEnv("NODE_ENV") === "development" || handleEnv("NODE_ENV") === "prebuild";
export default function fetchRetry(input, init) {
    return _fetchRetry.apply(this, arguments);
};
function _fetchRetry() {
    _fetchRetry = _async_to_generator(regeneratorRuntime.mark(function _callee(input, init) {
        var retryLeft;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    retryLeft = MAX_NB_RETRY;
                case 1:
                    if (!(retryLeft > 0)) {
                        _ctx.next = 18;
                        break;
                    }
                    _ctx.prev = 2;
                    _ctx.next = 5;
                    return fetch(input, init);
                case 5:
                    return _ctx.abrupt("return", _ctx.sent);
                case 8:
                    _ctx.prev = 8;
                    _ctx.t0 = _ctx["catch"](2);
                    console.log("Retry:".concat(input));
                    _ctx.next = 13;
                    return delay(RETRY_DELAY_MS);
                case 13:
                    _ctx.prev = 13;
                    retryLeft -= 1;
                    return _ctx.finish(13);
                case 16:
                    _ctx.next = 1;
                    break;
                case 18:
                    throw new Error("Too many retries ".concat(input));
                case 19:
                case "end":
                    return _ctx.stop();
            }
        }, _callee, null, [
            [
                2,
                8,
                13,
                16
            ]
        ]);
    }));
    return _fetchRetry.apply(this, arguments);
}
export var delay = function(time) {
    return new Promise(function(resolve) {
        return setTimeout(resolve, time);
    });
};
/**
 * Parse safe JSON and catch error
 * @param response
 */ export function safeParseJSON(response) {
    return _safeParseJSON.apply(this, arguments);
}
function _safeParseJSON() {
    _safeParseJSON = _async_to_generator(regeneratorRuntime.mark(function _callee(response) {
        var body;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    _ctx.next = 2;
                    return response.text();
                case 2:
                    body = _ctx.sent;
                    _ctx.prev = 3;
                    return _ctx.abrupt("return", JSON.parse(body));
                case 7:
                    _ctx.prev = 7;
                    _ctx.t0 = _ctx["catch"](3);
                    console.error("Error:", _ctx.t0);
                    console.error("Response body:", body);
                    console.error("Response:", response);
                    // console.error(`Error with status: ${response?.status}, url: ${response?.url}`);
                    throw new Error("Error with status: ".concat(response === null || response === void 0 ? void 0 : response.status, ", url: ").concat(response === null || response === void 0 ? void 0 : response.url));
                case 13:
                case "end":
                    return _ctx.stop();
            }
        }, _callee, null, [
            [
                3,
                7
            ]
        ]);
    }));
    return _safeParseJSON.apply(this, arguments);
}
/**
 * method to generate the redirect Coverage path
 * @param paths
 * @param slug
 */ export var getRedirectCoveragePath = function(paths, slug) {
    return "".concat(paths === null || paths === void 0 ? void 0 : paths.join("/"), "/").concat(slug || "", "/").concat(PAGES.SHOPPING_CART);
};
/**
 * Method to return the api for static pages.
 * Retrieve pages from consumer/business/grandiaziende
 * @param appType
 */ var getApiPages = function(appType) {
    var apiStaticPages;
    switch(appType){
        case "consumer":
            apiStaticPages = API.CMS_GET_PAGES_CONSUMER;
            break;
        case "business-homepage":
            apiStaticPages = API.CMS_GET_PAGES_BUSINESS;
            break;
        case "grandiaziende-homepage":
            apiStaticPages = API.CMS_GET_PAGES_GRANDIAZIENDE;
            break;
        default:
            apiStaticPages = API.CMS_GET_PAGES_CONSUMER;
            break;
    }
    return apiStaticPages;
};
/**
 * Method to organize CMS Pages from AEM and return the NextJS format
 * @param cmsPages
 */ var organizeCMSPages = function(cmsPages) {
    var ref;
    var pagesToReturn = [];
    var cmsPagesLength = (ref = Object.keys(cmsPages)) === null || ref === void 0 ? void 0 : ref.length;
    if (cmsPagesLength <= 0) {
        return pagesToReturn;
    }
    Object.keys(cmsPages).forEach(function(cmsPageKey) {
        var ref, ref1, ref2;
        var _cmsPageKey = cmsPages[cmsPageKey], pagesparams = _cmsPageKey.pagesparams, widgets = _cmsPageKey.widgets, seo = _cmsPageKey.seo, tagging = _cmsPageKey.tagging, heroslider = _cmsPageKey.heroslider, stickybanner = _cmsPageKey.stickybanner, supportwhatsapp = _cmsPageKey.supportwhatsapp;
        var layout = "BLANK";
        var haveProducts = (pagesparams === null || pagesparams === void 0 ? void 0 : (ref = pagesparams.apiPath) === null || ref === void 0 ? void 0 : ref.apiOfferList) && (pagesparams === null || pagesparams === void 0 ? void 0 : (ref1 = pagesparams.apiPath) === null || ref1 === void 0 ? void 0 : ref1.apiProductsList);
        var haveDevice = pagesparams === null || pagesparams === void 0 ? void 0 : (ref2 = pagesparams.apiPath) === null || ref2 === void 0 ? void 0 : ref2.apiDeviceList;
        if (haveProducts || haveDevice) {
            layout = "HUB";
        }
        if (pagesparams === null || pagesparams === void 0 ? void 0 : pagesparams.key) {
            var ref3, ref4, ref5, ref6, ref7, ref8, ref9, ref10, ref11, ref12, ref13;
            var pageToAdd = {
                params: {
                    key: pagesparams.key,
                    layout: layout,
                    haveHeader: ((ref3 = pagesparams.visibility) === null || ref3 === void 0 ? void 0 : ref3.haveHeader) === "true",
                    isHeaderSmall: ((ref4 = pagesparams.visibility) === null || ref4 === void 0 ? void 0 : ref4.isHeaderSmall) === "true",
                    haveFooter: ((ref5 = pagesparams.visibility) === null || ref5 === void 0 ? void 0 : ref5.haveFooter) === "true",
                    isShow: ((ref6 = pagesparams.visibility) === null || ref6 === void 0 ? void 0 : ref6.showPages) === "true",
                    isDisabledInitialTrack: ((ref7 = pagesparams.visibility) === null || ref7 === void 0 ? void 0 : ref7.isDisabledInitialTrack) === "true",
                    isProtected: ((ref8 = pagesparams.visibility) === null || ref8 === void 0 ? void 0 : ref8.isProtected) === "true",
                    protectedPass: ((ref9 = pagesparams.visibility) === null || ref9 === void 0 ? void 0 : ref9.protectedPass) || "",
                    path: ((ref10 = pagesparams.urlBuilder) === null || ref10 === void 0 ? void 0 : ref10.map(function(urlBuilder) {
                        var ref, ref1;
                        return ((ref1 = (ref = urlBuilder.page) === null || ref === void 0 ? void 0 : ref.trim()) === null || ref1 === void 0 ? void 0 : ref1.toLowerCase()) || "";
                    })) || [
                        ""
                    ],
                    elements: _object_spread({}, (pagesparams === null || pagesparams === void 0 ? void 0 : (ref11 = pagesparams.apiPath) === null || ref11 === void 0 ? void 0 : ref11.apiProductsList) && {
                        apiProductsList: pagesparams.apiPath.apiProductsList
                    }, (pagesparams === null || pagesparams === void 0 ? void 0 : (ref12 = pagesparams.apiPath) === null || ref12 === void 0 ? void 0 : ref12.apiOfferList) && {
                        apiOfferList: pagesparams.apiPath.apiOfferList
                    }, (pagesparams === null || pagesparams === void 0 ? void 0 : (ref13 = pagesparams.apiPath) === null || ref13 === void 0 ? void 0 : ref13.apiDeviceList) && {
                        apiDeviceList: pagesparams.apiPath.apiDeviceList
                    }, heroslider && (heroslider === null || heroslider === void 0 ? void 0 : heroslider.slides) && {
                        heroslider: heroslider
                    }, widgets && (widgets === null || widgets === void 0 ? void 0 : widgets.sliders) && {
                        widgets: widgets
                    }, stickybanner && {
                        stickybanner: stickybanner
                    }, seo && {
                        seo: seo
                    }, supportwhatsapp && {
                        supportwhatsapp: supportwhatsapp
                    }, tagging && {
                        taggingOptions: {
                            event_category: (tagging === null || tagging === void 0 ? void 0 : tagging.eventCategory) || "",
                            page_section: (tagging === null || tagging === void 0 ? void 0 : tagging.pageSection) || "",
                            page_subsection: (tagging === null || tagging === void 0 ? void 0 : tagging.pageSubsection) || "",
                            page_type: (tagging === null || tagging === void 0 ? void 0 : tagging.pageType) || "",
                            event: (tagging === null || tagging === void 0 ? void 0 : tagging.event) || "",
                            eventLabel: (tagging === null || tagging === void 0 ? void 0 : tagging.eventLabel) || "",
                            journey_type: (tagging === null || tagging === void 0 ? void 0 : tagging.journeyType) || "",
                            product_target_segment: (tagging === null || tagging === void 0 ? void 0 : tagging.productTargetSegment) || "",
                            product_category: (tagging === null || tagging === void 0 ? void 0 : tagging.productCategory) || ""
                        }
                    })
                }
            };
            pagesToReturn.push(pageToAdd);
        }
    });
    return pagesToReturn;
};
/**
 *  Method to get the page Navigation api
 * @param appType
 */ var getPageNavigationApi = function(appType) {
    var pageNavApi = API.CMS_GET_PAGE_NAVIGATION;
    switch(appType){
        case "grandiaziende-homepage":
            pageNavApi = API.CMS_GET_PAGE_GRANDIAZIENDE_NAVIGATION;
            break;
        case "business-homepage":
            pageNavApi = API.CMS_GET_PAGE_BUSINESS_NAVIGATION;
            break;
        default:
            break;
    }
    return pageNavApi;
};
/**
 * Method to return the page navigation by type
 * @param appType
 * @param env
 */ var retrievePageNavigation = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(appType, env) {
        var ms, apiEnv, apiPageNavigation, pageNavigationEp, pageNavigationRes;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    ms = Date.now();
                    apiEnv = env || handleEnv("NX_CMS");
                    apiPageNavigation = getPageNavigationApi(appType);
                    _ctx.next = 5;
                    return fetchRetry("".concat(apiEnv, "/").concat(isDevLocal && !env ? apiPageNavigation : "".concat(apiPageNavigation, ".json"), "?t=").concat(ms));
                case 5:
                    pageNavigationEp = _ctx.sent;
                    _ctx.next = 8;
                    return delay(200);
                case 8:
                    _ctx.next = 10;
                    return safeParseJSON(pageNavigationEp);
                case 10:
                    pageNavigationRes = _ctx.sent;
                    return _ctx.abrupt("return", pageNavigationRes);
                case 12:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function retrievePageNavigation(appType, env) {
        return _ref.apply(this, arguments);
    };
}();
/**
 * Retrieve products if is type BLANK and have Custom Hub / Custom solution hub
 * @param path
 * @param allPaths
 * @param retrievedProducts
 */ var retrieveProductsInCustomHub = function(path, allPaths, retrievedProducts) {
    var ref, ref1, ref2, ref3, ref4, ref5;
    var customHub = ((ref = path.params.elements) === null || ref === void 0 ? void 0 : (ref1 = ref.widgets) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.sliders) === null || ref2 === void 0 ? void 0 : ref2.filter(function(slide) {
        return slide.type === IWidgetType.HUB_CUSTOM;
    })) || [];
    var customSolutionHub = ((ref3 = path.params.elements) === null || ref3 === void 0 ? void 0 : (ref4 = ref3.widgets) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.sliders) === null || ref5 === void 0 ? void 0 : ref5.filter(function(slide) {
        return slide.type === IWidgetType.HUB_SOLUTIONS_CUSTOM;
    })) || [];
    if (customHub.length === 0 && customSolutionHub.length === 0) return {
        path: path,
        allProductsInCustomsHubs: [],
        allAlternativeProducts: []
    };
    var allProductsInCustomsHubs = [];
    var allAlternativeProducts = [];
    if (customHub.length > 0) {
        customHub.forEach(function(cH) {
            var ref;
            (ref = cH.elements) === null || ref === void 0 ? void 0 : ref.forEach(function(el) {
                if (el === null || el === void 0 ? void 0 : el.product) allProductsInCustomsHubs.push(_object_spread_props(_object_spread({}, el.product), {
                    key: el === null || el === void 0 ? void 0 : el.key
                }));
            });
        });
    }
    if (customSolutionHub.length > 0) {
        customSolutionHub.forEach(function(cSH) {
            cSH.elements.forEach(function(el) {
                if (el === null || el === void 0 ? void 0 : el.product) allProductsInCustomsHubs.push(_object_spread_props(_object_spread({}, el.product), {
                    key: el === null || el === void 0 ? void 0 : el.key
                }));
            });
        });
    }
    // if (allIdsInHubs.length > 0) {
    //   const hubsInPage = allPaths?.filter((p) => p.params.layout === 'HUB');
    //   hubsInPage.forEach((hub) => {
    //     const productsHub = hub.params.elements?.productsAll || [];
    //     if (productsHub?.length > 0) {
    //       productsHub.forEach((productInHub) => {
    //         const productInHubOfferId = productInHub.offerId.toString();
    //         if (allIdsInHubs.includes(productInHubOfferId)) {
    //           const isPresentProduct = allProductsInCustomsHubs.find(
    //             (aPICH) => aPICH.offerId.toString() === productInHubOfferId
    //           );
    //           if (!isPresentProduct) {
    //             allProductsInCustomsHubs.push(productInHub);
    //           }
    //         }
    //       });
    //     }
    //   });
    // }
    // region Retrieve all alternative products
    allProductsInCustomsHubs.forEach(function(productInCustomHub) {
        var ref = productInCustomHub || {}, alternativeProductOfferList = ref.alternativeProductOfferList, key = ref.key;
        var hubAllForKey = retrievedProducts === null || retrievedProducts === void 0 ? void 0 : retrievedProducts.find(function(productsInfo) {
            return (productsInfo === null || productsInfo === void 0 ? void 0 : productsInfo.key) === key;
        });
        var productsAll = (hubAllForKey || {}).productsAll;
        productsAll === null || productsAll === void 0 ? void 0 : productsAll.forEach(function(p) {
            var productId = p.cmsId.toString();
            if (alternativeProductOfferList === null || alternativeProductOfferList === void 0 ? void 0 : alternativeProductOfferList.includes(productId)) {
                var isPresentProduct = allAlternativeProducts.find(function(aP) {
                    var ref;
                    return ((ref = aP.cmsId) === null || ref === void 0 ? void 0 : ref.toString()) == productId && (aP === null || aP === void 0 ? void 0 : aP.key) === (productInCustomHub === null || productInCustomHub === void 0 ? void 0 : productInCustomHub.key);
                });
                if (!isPresentProduct) {
                    allAlternativeProducts.push(p);
                }
            }
        });
    });
    // endregion
    return {
        path: path,
        allAlternativeProducts: allAlternativeProducts,
        allProductsInCustomsHubs: allProductsInCustomsHubs
    };
};
/**
 * Retrieve products if is type BLANK and have Products widget
 * @param path
 * @param paths
 */ var retrieveProductsInProductsWidget = function(path, paths) {
    var ref, ref1, ref2, ref3;
    return _object_spread_props(_object_spread({}, path), {
        params: _object_spread_props(_object_spread({}, path.params), {
            elements: _object_spread_props(_object_spread({}, path.params.elements), {
                widgets: _object_spread_props(_object_spread({}, ((ref = path.params.elements) === null || ref === void 0 ? void 0 : ref.widgets) || {}), {
                    sliders: ((ref1 = path.params.elements) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.widgets) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.sliders) === null || ref3 === void 0 ? void 0 : ref3.map(function(wdg) {
                        switch(wdg.type){
                            case IWidgetType.HUB_CUSTOM:
                            case IWidgetType.HUB_SOLUTIONS_CUSTOM:
                            case IWidgetType.PRODUCTS_SLIDER:
                                var ref;
                                return _object_spread_props(_object_spread({}, wdg), {
                                    elements: ((ref = wdg.elements) === null || ref === void 0 ? void 0 : ref.map(function(elWdg) {
                                        var ref, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8;
                                        var foundedPage = paths.find(function(p) {
                                            return p.params.key === elWdg.key;
                                        });
                                        if (!(foundedPage === null || foundedPage === void 0 ? void 0 : (ref = foundedPage.params) === null || ref === void 0 ? void 0 : (ref1 = ref.elements) === null || ref1 === void 0 ? void 0 : ref1.productsAll) || (foundedPage === null || foundedPage === void 0 ? void 0 : (ref2 = foundedPage.params) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.elements) === null || ref3 === void 0 ? void 0 : (ref4 = ref3.productsAll) === null || ref4 === void 0 ? void 0 : ref4.length) === 0) return elWdg;
                                        var foundedProduct = (foundedPage === null || foundedPage === void 0 ? void 0 : (ref5 = foundedPage.params) === null || ref5 === void 0 ? void 0 : (ref6 = ref5.elements) === null || ref6 === void 0 ? void 0 : (ref7 = ref6.productsAll) === null || ref7 === void 0 ? void 0 : ref7.find(function(pA) {
                                            var ref, ref1;
                                            return (pA === null || pA === void 0 ? void 0 : (ref = pA.cmsId) === null || ref === void 0 ? void 0 : ref.toString()) == (elWdg === null || elWdg === void 0 ? void 0 : (ref1 = elWdg.id) === null || ref1 === void 0 ? void 0 : ref1.toString());
                                        })) || null;
                                        return _object_spread_props(_object_spread({}, elWdg), {
                                            product: foundedProduct ? _object_spread_props(_object_spread({}, foundedProduct), {
                                                redirectCoveragePath: getRedirectCoveragePath(path === null || path === void 0 ? void 0 : (ref8 = path.params) === null || ref8 === void 0 ? void 0 : ref8.path, foundedProduct === null || foundedProduct === void 0 ? void 0 : foundedProduct.slug)
                                            }) : null
                                        });
                                    })) || null
                                });
                            default:
                                return wdg;
                        }
                    })) || []
                })
            })
        })
    });
};
/**
 * Method to retrieve all information for products/page navigation/offerlists
 * @param key
 * @param path
 * @param apiProductlist
 * @param apiOfferList
 * @param env
 */ var retrieveAllProductsInfoByApi = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(key, path, apiProductlist, apiOfferList, env) {
        var ref, ms, apiEnv, epOffList, epProdList, offerListEp, offerListRes, productsEp, productsRes, offLists, ref1, ref2, allProdsFormatted, allProdsWithProductRefs, allProdsOrdered, productsAll;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    ;
                    ms = Date.now();
                    apiEnv = env || handleEnv("NX_CMS");
                    epOffList = isDevLocal && !env ? apiOfferList : "".concat(apiOfferList, ".json");
                    epProdList = isDevLocal && !env ? apiProductlist : "".concat(apiProductlist, ".json");
                    _ctx.next = 7;
                    return fetchRetry("".concat(apiEnv, "/").concat(epOffList, "?t=").concat(ms));
                case 7:
                    offerListEp = _ctx.sent;
                    _ctx.next = 10;
                    return safeParseJSON(offerListEp);
                case 10:
                    offerListRes = _ctx.sent;
                    _ctx.next = 13;
                    return delay(200);
                case 13:
                    _ctx.next = 15;
                    return fetchRetry("".concat(apiEnv, "/").concat(epProdList, "?t=").concat(ms));
                case 15:
                    productsEp = _ctx.sent;
                    _ctx.next = 18;
                    return safeParseJSON(productsEp);
                case 18:
                    productsRes = _ctx.sent;
                    _ctx.next = 21;
                    return delay(200);
                case 21:
                    offLists = [];
                    if (offerListRes === null || offerListRes === void 0 ? void 0 : (ref = offerListRes.offerlist) === null || ref === void 0 ? void 0 : ref.offers) {
                        ;
                        offerListRes === null || offerListRes === void 0 ? void 0 : (ref1 = offerListRes.offerlist) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.offers) === null || ref2 === void 0 ? void 0 : ref2.forEach(function(off) {
                            offLists.push((off === null || off === void 0 ? void 0 : off.id) || "");
                        });
                    }
                    allProdsFormatted = [];
                    Object.keys(productsRes).forEach(function(pResKey) {
                        allProdsFormatted.push(_object_spread({
                            cmsId: pResKey
                        }, organizeProduct(productsRes[pResKey])));
                    });
                    allProdsWithProductRefs = allProdsFormatted === null || allProdsFormatted === void 0 ? void 0 : allProdsFormatted.map(function(prod) {
                        var ref, ref1;
                        if ((prod === null || prod === void 0 ? void 0 : (ref = prod.productRef) === null || ref === void 0 ? void 0 : ref.id) && (prod === null || prod === void 0 ? void 0 : (ref1 = prod.productRef) === null || ref1 === void 0 ? void 0 : ref1.hubKey) === key) {
                            var foundedProdRef = allProdsFormatted.find(function(el) {
                                return el.cmsId == prod.productRef.id;
                            });
                            if (foundedProdRef) {
                                return _object_spread({}, organizeProductWithProductRef(foundedProdRef, prod));
                            }
                        }
                        return prod;
                    });
                    allProdsOrdered = [];
                    offLists.forEach(function(offList) {
                        var foundedProd = allProdsWithProductRefs.filter(function(prod) {
                            return prod.cmsId == offList;
                        });
                        if ((foundedProd === null || foundedProd === void 0 ? void 0 : foundedProd.length) > 0) {
                            foundedProd.forEach(function(foundedP) {
                                return allProdsOrdered.push(foundedP);
                            });
                        }
                    });
                    productsAll = allProdsOrdered === null || allProdsOrdered === void 0 ? void 0 : allProdsOrdered.filter(function(aPF) {
                        return offLists.includes(aPF.cmsId.toString());
                    }).map(function(aPF) {
                        var ref, ref1;
                        return _object_spread_props(_object_spread({}, aPF), {
                            redirectCoveragePath: getRedirectCoveragePath([
                                path
                            ], (aPF === null || aPF === void 0 ? void 0 : aPF.slug) || ""),
                            isShowedInHub: ((ref = aPF.offerVisibility) === null || ref === void 0 ? void 0 : ref.showInHub) === "true" || ((ref1 = aPF.offerVisibility) === null || ref1 === void 0 ? void 0 : ref1.showInSolutions) === "true" || false
                        });
                    });
                    return _ctx.abrupt("return", {
                        key: key,
                        productsAll: productsAll,
                        offersLists: offLists
                    });
                case 30:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function retrieveAllProductsInfoByApi(key, path, apiProductlist, apiOfferList, env) {
        return _ref.apply(this, arguments);
    };
}();
/**
 * Method to retrieve all information for devices
 * @param key
 * @param path
 * @param apiDevicesList
 * @param env
 */ var retrieveAllDevicesInfoByApi = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(key, path, apiDevicesList, env) {
        var ref, ref1, ms, deviceOrderListPath, apiFolder, apiEnv, epDeviceList, epDeviceOrderList, devicesEp, devicesRes, devicesOrderListEp, devicesOrderListRes, devicesToReturn, allDevices, getRemovedComposedSlug, findDeviceBySlug, deviceOrderNames, orderedDevices;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    ;
                    ms = Date.now();
                    deviceOrderListPath = "devices-order/order-list";
                    apiFolder = apiDevicesList.slice(0, apiDevicesList.lastIndexOf("/"));
                    apiEnv = env || handleEnv("NX_CMS");
                    epDeviceList = isDevLocal && !env ? apiDevicesList : "".concat(apiDevicesList, ".json");
                    epDeviceOrderList = isDevLocal && !env ? "".concat(apiFolder, "/").concat(deviceOrderListPath) : "".concat(apiFolder, "/").concat(deviceOrderListPath, ".json");
                    _ctx.next = 9;
                    return fetchRetry("".concat(apiEnv, "/").concat(epDeviceList, "?t=").concat(ms));
                case 9:
                    devicesEp = _ctx.sent;
                    _ctx.next = 12;
                    return safeParseJSON(devicesEp);
                case 12:
                    devicesRes = _ctx.sent;
                    _ctx.next = 15;
                    return delay(200);
                case 15:
                    _ctx.next = 17;
                    return fetchRetry("".concat(apiEnv, "/").concat(epDeviceOrderList, "?t=").concat(ms));
                case 17:
                    devicesOrderListEp = _ctx.sent;
                    _ctx.next = 20;
                    return safeParseJSON(devicesOrderListEp);
                case 20:
                    devicesOrderListRes = _ctx.sent;
                    _ctx.next = 23;
                    return delay(200);
                case 23:
                    devicesToReturn = [];
                    allDevices = [];
                    Object.keys(devicesRes).forEach(function(dResKey) {
                        if (!dResKey.includes("order")) {
                            allDevices.push(_object_spread({}, organizeDevice(devicesRes[dResKey], dResKey)));
                        }
                    });
                    devicesToReturn = allDevices;
                    getRemovedComposedSlug = function(device) {
                        var ref, ref1, ref2, ref3;
                        var brand = (device === null || device === void 0 ? void 0 : (ref = device.devicedetail) === null || ref === void 0 ? void 0 : (ref1 = ref.deviceBrand) === null || ref1 === void 0 ? void 0 : ref1.toLowerCase()) || "";
                        var slug = (device === null || device === void 0 ? void 0 : (ref2 = device.devicedetail) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.slug) === null || ref3 === void 0 ? void 0 : ref3.toLowerCase()) || "";
                        return slug.includes(brand) ? slug.split("".concat(brand, "-"))[1] : slug;
                    };
                    findDeviceBySlug = function(allDevicesToFilter, deviceOrderName) {
                        return allDevicesToFilter === null || allDevicesToFilter === void 0 ? void 0 : allDevicesToFilter.find(function(el) {
                            var removedComposedSlug = getRemovedComposedSlug(el);
                            return (removedComposedSlug === null || removedComposedSlug === void 0 ? void 0 : removedComposedSlug.toLowerCase()) === (deviceOrderName === null || deviceOrderName === void 0 ? void 0 : deviceOrderName.toLowerCase());
                        });
                    };
                    deviceOrderNames = (devicesOrderListRes === null || devicesOrderListRes === void 0 ? void 0 : (ref = devicesOrderListRes.offerlist) === null || ref === void 0 ? void 0 : (ref1 = ref.offers) === null || ref1 === void 0 ? void 0 : ref1.map(function(el) {
                        return el.id;
                    })) || [];
                    orderedDevices = (deviceOrderNames === null || deviceOrderNames === void 0 ? void 0 : deviceOrderNames.length) <= 0 ? devicesToReturn : [];
                    if ((deviceOrderNames === null || deviceOrderNames === void 0 ? void 0 : deviceOrderNames.length) > 0) {
                        orderedDevices = allDevices.filter(function(el) {
                            var removedComposedSlug = getRemovedComposedSlug(el);
                            return !(deviceOrderNames === null || deviceOrderNames === void 0 ? void 0 : deviceOrderNames.includes(removedComposedSlug));
                        });
                        deviceOrderNames.reverse().forEach(function(deviceOrderName) {
                            var foundedDevice = findDeviceBySlug(devicesToReturn, deviceOrderName);
                            if (foundedDevice) {
                                orderedDevices.unshift(foundedDevice);
                            }
                        });
                        devicesToReturn = orderedDevices;
                    }
                    return _ctx.abrupt("return", {
                        key: key,
                        allDevices: devicesToReturn
                    });
                case 33:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
    return function retrieveAllDevicesInfoByApi(key, path, apiDevicesList, env) {
        return _ref.apply(this, arguments);
    };
}();
/**
 * Method to return the PDP and Shopping cart from pathPage and product
 * @param pathPage
 * @param prod
 * @param productsAll
 * @param offersLists
 */ var generatePDPAndShoppingCart = function(pathPage, prod, productsAll) {
    var offersLists = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : [];
    var ref, ref1, ref2;
    var seoProduct = prod === null || prod === void 0 ? void 0 : prod.seo;
    var path = getRedirectCoveragePath(pathPage === null || pathPage === void 0 ? void 0 : (ref = pathPage.params) === null || ref === void 0 ? void 0 : ref.path, (prod === null || prod === void 0 ? void 0 : prod.slug) || "");
    return {
        pdp: {
            params: _object_spread_props(_object_spread({}, pathPage.params), {
                layout: "PRODUCT_DETAIL",
                key: "".concat(pathPage.params.key, "_").concat(prod.slug),
                slug: (prod === null || prod === void 0 ? void 0 : prod.slug) || "",
                path: _to_consumable_array(pathPage.params.path).concat([
                    prod === null || prod === void 0 ? void 0 : prod.slug
                ]),
                elements: _object_spread_props(_object_spread({}, pathPage.params.elements, seoProduct && {
                    seo: seoProduct
                }), {
                    taggingOptions: _object_spread_props(_object_spread({}, pathPage.params.elements.taggingOptions), {
                        page_subsection: "product page",
                        page_type: "product details"
                    }),
                    productsAll: productsAll,
                    offersLists: offersLists,
                    product: _object_spread_props(_object_spread({}, prod), {
                        redirectCoveragePath: path
                    }),
                    widgets: null
                })
            })
        },
        shoppingCart: {
            params: _object_spread_props(_object_spread({}, pathPage.params), {
                layout: "SHOPPING_CART",
                key: "".concat(pathPage.params.key, "_").concat(prod.slug, "_shopping-cart"),
                slug: prod.slug,
                path: _to_consumable_array(pathPage.params.path).concat([
                    prod.slug,
                    "shopping-cart"
                ]),
                elements: _object_spread_props(_object_spread({}, pathPage.params.elements, seoProduct && {
                    seo: seoProduct
                }), {
                    productsAll: productsAll,
                    offersLists: offersLists,
                    product: _object_spread_props(_object_spread({}, prod), {
                        redirectCoveragePath: path,
                        hubPath: (pathPage === null || pathPage === void 0 ? void 0 : (ref1 = pathPage.params) === null || ref1 === void 0 ? void 0 : (ref2 = ref1.path) === null || ref2 === void 0 ? void 0 : ref2.join("/")) || ""
                    }),
                    widgets: null
                })
            })
        }
    };
};
/**
 * Method to return the PDP for device
 * @param pathPage
 * @param device
 * @param device
 * @param allDevices
 */ var generatePDPDevice = function(pathPage, device, allDevices) {
    var ref, ref1, ref2, ref3;
    var seoDevice = device === null || device === void 0 ? void 0 : device.seo;
    var taggingOptionsDevice = device === null || device === void 0 ? void 0 : device.tagging;
    var widgetDevice = device === null || device === void 0 ? void 0 : device.widgets;
    var widgetTableCharacteristic = device === null || device === void 0 ? void 0 : device.widgetscharacteristi;
    return {
        pdp: {
            params: _object_spread_props(_object_spread({}, pathPage.params), {
                layout: "PRODUCT_DETAIL",
                key: "".concat(pathPage.params.key, "_").concat(device === null || device === void 0 ? void 0 : (ref = device.devicedetail) === null || ref === void 0 ? void 0 : ref.slug),
                slug: (device === null || device === void 0 ? void 0 : (ref1 = device.devicedetail) === null || ref1 === void 0 ? void 0 : ref1.slug) || "",
                path: _to_consumable_array(pathPage.params.path).concat([
                    device === null || device === void 0 ? void 0 : (ref2 = device.devicedetail) === null || ref2 === void 0 ? void 0 : ref2.slug
                ]),
                elements: _object_spread(_object_spread_props(_object_spread({}, pathPage.params.elements, seoDevice && {
                    seo: seoDevice
                }), {
                    taggingOptions: _object_spread_props(_object_spread({}, pathPage.params.elements.taggingOptions), {
                        event_category: (taggingOptionsDevice === null || taggingOptionsDevice === void 0 ? void 0 : taggingOptionsDevice.eventCategory) || "",
                        page_section: (taggingOptionsDevice === null || taggingOptionsDevice === void 0 ? void 0 : taggingOptionsDevice.pageSection) || "",
                        event: (taggingOptionsDevice === null || taggingOptionsDevice === void 0 ? void 0 : taggingOptionsDevice.event) || "",
                        eventLabel: (taggingOptionsDevice === null || taggingOptionsDevice === void 0 ? void 0 : taggingOptionsDevice.eventLabel) || "",
                        journey_type: (taggingOptionsDevice === null || taggingOptionsDevice === void 0 ? void 0 : taggingOptionsDevice.journeyType) || "",
                        product_target_segment: (taggingOptionsDevice === null || taggingOptionsDevice === void 0 ? void 0 : taggingOptionsDevice.productTargetSegment) || "",
                        product_category: ((ref3 = taggingOptionsDevice === null || taggingOptionsDevice === void 0 ? void 0 : taggingOptionsDevice.productCategory) === null || ref3 === void 0 ? void 0 : ref3.map(function(el) {
                            return el.key;
                        })) || "",
                        page_subsection: (taggingOptionsDevice === null || taggingOptionsDevice === void 0 ? void 0 : taggingOptionsDevice.pageSubsection) || "device page",
                        page_type: (taggingOptionsDevice === null || taggingOptionsDevice === void 0 ? void 0 : taggingOptionsDevice.pageType) || "device details"
                    }),
                    allDevices: allDevices,
                    device: {
                        devicedetail: (device === null || device === void 0 ? void 0 : device.devicedetail) || undefined
                    }
                }), widgetDevice && {
                    widgets: widgetDevice
                }, widgetTableCharacteristic && {
                    widgetsCharacteristics: widgetTableCharacteristic
                })
            })
        }
    };
};
export { getApiPages, organizeCMSPages, retrieveAllProductsInfoByApi, retrieveAllDevicesInfoByApi, retrievePageNavigation, isDevLocal, retrieveProductsInCustomHub, retrieveProductsInProductsWidget, generatePDPAndShoppingCart, generatePDPDevice };
