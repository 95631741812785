import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import regeneratorRuntime from "/home/jenkins/agent/workspace/web-sunrise-prod/web-sunrise-landing-deploy-prod/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { handleEnv } from "@vfit/shared/providers";
import fetchRetry, { delay, generatePDPAndShoppingCart, generatePDPDevice, getApiPages, getRedirectCoveragePath, isDevLocal, organizeCMSPages, retrieveAllDevicesInfoByApi, retrieveAllProductsInfoByApi, retrievePageNavigation, retrieveProductsInCustomHub, retrieveProductsInProductsWidget, safeParseJSON } from "./staticPathPages.utils";
var getStaticPathPages = function() {
    var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(appType, env) {
        var startTime, ms, apiEnv, apiPages, cmsPages, cmsPagesRes, paths, pageNavigation, allProductsInfo, hubs, allHubsApi, _iteratorNormalCompletion, _didIteratorError, _iteratorError, _iterator, _step, hubApi, hubApiRes, pathsToAdd, endTime;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    startTime = new Date().getTime();
                    _ctx.prev = 1;
                    console.info("## getStaticPathPages START ## timestampt: ".concat(startTime));
                    ms = Date.now();
                    apiEnv = env || handleEnv("NX_CMS");
                    apiPages = getApiPages(appType);
                    _ctx.next = 8;
                    return fetchRetry("".concat(apiEnv, "/").concat(isDevLocal && !env ? apiPages : "".concat(apiPages, ".json"), "?t=").concat(ms));
                case 8:
                    cmsPages = _ctx.sent;
                    _ctx.next = 11;
                    return delay(200);
                case 11:
                    _ctx.next = 13;
                    return safeParseJSON(cmsPages);
                case 13:
                    cmsPagesRes = _ctx.sent;
                    _ctx.next = 16;
                    return delay(200);
                case 16:
                    paths = organizeCMSPages(cmsPagesRes);
                    // region Filter Page on isShow
                    paths = paths === null || paths === void 0 ? void 0 : paths.filter(function(p) {
                        return p.params.isShow;
                    });
                    _ctx.next = 20;
                    return retrievePageNavigation(appType, env);
                case 20:
                    pageNavigation = _ctx.sent;
                    paths = paths.map(function(path) {
                        return _object_spread_props(_object_spread({}, path), {
                            params: _object_spread_props(_object_spread({}, path.params), {
                                elements: _object_spread_props(_object_spread({}, path.params.elements), {
                                    pageNavigation: pageNavigation
                                })
                            })
                        });
                    });
                    allProductsInfo = [];
                    hubs = paths === null || paths === void 0 ? void 0 : paths.filter(function(p) {
                        return p.params.layout === "HUB";
                    });
                    allHubsApi = hubs.map(function(p) {
                        var ref, ref1, ref2, ref3, ref4, ref5;
                        if ((ref = p.params) === null || ref === void 0 ? void 0 : (ref1 = ref.elements) === null || ref1 === void 0 ? void 0 : ref1.apiDeviceList) {
                            var ref6, ref7;
                            return retrieveAllDevicesInfoByApi(p.params.key, p.params.path.join("/"), ((ref6 = p.params) === null || ref6 === void 0 ? void 0 : (ref7 = ref6.elements) === null || ref7 === void 0 ? void 0 : ref7.apiDeviceList) || "", env);
                        }
                        return retrieveAllProductsInfoByApi(p.params.key, p.params.path.join("/"), ((ref2 = p.params) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.elements) === null || ref3 === void 0 ? void 0 : ref3.apiProductsList) || "", (p === null || p === void 0 ? void 0 : (ref4 = p.params) === null || ref4 === void 0 ? void 0 : (ref5 = ref4.elements) === null || ref5 === void 0 ? void 0 : ref5.apiOfferList) || "", env);
                    });
                    _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
                    _ctx.prev = 26;
                    _iterator = allHubsApi[Symbol.iterator]();
                case 28:
                    if (_iteratorNormalCompletion = (_step = _iterator.next()).done) {
                        _ctx.next = 37;
                        break;
                    }
                    hubApi = _step.value;
                    _ctx.next = 32;
                    return hubApi;
                case 32:
                    hubApiRes = _ctx.sent;
                    allProductsInfo.push(hubApiRes);
                case 34:
                    _iteratorNormalCompletion = true;
                    _ctx.next = 28;
                    break;
                case 37:
                    _ctx.next = 43;
                    break;
                case 39:
                    _ctx.prev = 39;
                    _ctx.t0 = _ctx["catch"](26);
                    _didIteratorError = true;
                    _iteratorError = _ctx.t0;
                case 43:
                    _ctx.prev = 43;
                    _ctx.prev = 44;
                    if (!_iteratorNormalCompletion && _iterator.return != null) {
                        _iterator.return();
                    }
                case 46:
                    _ctx.prev = 46;
                    if (!_didIteratorError) {
                        _ctx.next = 49;
                        break;
                    }
                    throw _iteratorError;
                case 49:
                    return _ctx.finish(46);
                case 50:
                    return _ctx.finish(43);
                case 51:
                    // endregion
                    if ((allProductsInfo === null || allProductsInfo === void 0 ? void 0 : allProductsInfo.length) > 0) {
                        allProductsInfo.forEach(function(product) {
                            var ref, ref1;
                            var key = product.key;
                            var pathPage = paths.find(function(p) {
                                return p.params.key === key;
                            });
                            paths = paths.map(function(p) {
                                if (p !== pathPage) {
                                    return p;
                                }
                                return {
                                    params: _object_spread_props(_object_spread({}, p.params), {
                                        elements: _object_spread({}, p.params.elements, (product === null || product === void 0 ? void 0 : product.offersLists) && {
                                            offersLists: product.offersLists || []
                                        }, (product === null || product === void 0 ? void 0 : product.productsAll) && {
                                            productsAll: product.productsAll || []
                                        }, (product === null || product === void 0 ? void 0 : product.allDevices) && {
                                            allDevices: product.allDevices || []
                                        })
                                    })
                                };
                            });
                            // region Generate PDP ShoppingCart Products
                            if ((product === null || product === void 0 ? void 0 : (ref = product.productsAll) === null || ref === void 0 ? void 0 : ref.length) > 0) {
                                product.productsAll.forEach(function(prod) {
                                    var pdpShoppingCart = generatePDPAndShoppingCart(pathPage, prod, product.productsAll, product.offersLists);
                                    switch(appType){
                                        case "consumer":
                                            paths.push(_object_spread({}, pdpShoppingCart.pdp), _object_spread({}, pdpShoppingCart.shoppingCart));
                                            break;
                                        default:
                                            paths.push(_object_spread({}, pdpShoppingCart.pdp));
                                            break;
                                    }
                                });
                            }
                            // endregion
                            // region Generate PDP Devices
                            if ((product === null || product === void 0 ? void 0 : (ref1 = product.allDevices) === null || ref1 === void 0 ? void 0 : ref1.length) > 0) {
                                product.allDevices.forEach(function(device) {
                                    var pdpDevice = generatePDPDevice(pathPage, device, product.allDevices);
                                    switch(appType){
                                        case "consumer":
                                            paths.push(_object_spread({}, pdpDevice.pdp));
                                            break;
                                        default:
                                            paths.push(_object_spread({}, pdpDevice.pdp));
                                            break;
                                    }
                                });
                            }
                        // endregion
                        });
                    }
                    // region Retrieve products if is type BLANK and have Products widget
                    paths = paths.map(function(path) {
                        if (path.params.layout !== "BLANK") return path;
                        return _object_spread({}, retrieveProductsInProductsWidget(path, paths));
                    });
                    pathsToAdd = [];
                    paths = paths.map(function(path) {
                        if (path.params.layout !== "BLANK") return path;
                        var ref = retrieveProductsInCustomHub(path, paths, allProductsInfo), pathProduct = ref.path, allAlternativeProducts = ref.allAlternativeProducts, allProductsInCustomsHubs = ref.allProductsInCustomsHubs;
                        if (allAlternativeProducts.length === 0 && allProductsInCustomsHubs.length === 0 && pathProduct) {
                            return pathProduct;
                        }
                        // ALL products in custom hubs
                        var productsAllInCustomHubs = _to_consumable_array(allProductsInCustomsHubs.map(function(prod) {
                            return _object_spread_props(_object_spread({}, prod), {
                                isShowedInHub: true
                            });
                        })).concat(_to_consumable_array((allAlternativeProducts === null || allAlternativeProducts === void 0 ? void 0 : allAlternativeProducts.map(function(prod) {
                            return _object_spread_props(_object_spread({}, prod), {
                                isShowedInHub: false
                            });
                        })) || [])).map(function(customHubProduct) {
                            var ref;
                            return _object_spread_props(_object_spread({}, customHubProduct), {
                                redirectCoveragePath: getRedirectCoveragePath(path === null || path === void 0 ? void 0 : (ref = path.params) === null || ref === void 0 ? void 0 : ref.path, (customHubProduct === null || customHubProduct === void 0 ? void 0 : customHubProduct.slug) || "")
                            });
                        });
                        // IF all products in custom hub is not empty, ADD ALL custom product in pages
                        if ((productsAllInCustomHubs === null || productsAllInCustomHubs === void 0 ? void 0 : productsAllInCustomHubs.length) > 0) {
                            productsAllInCustomHubs.forEach(function(prod) {
                                var pdpShoppingCart = generatePDPAndShoppingCart(path, prod, productsAllInCustomHubs);
                                switch(appType){
                                    case "consumer":
                                        pathsToAdd.push(_object_spread({}, pdpShoppingCart.pdp), _object_spread({}, pdpShoppingCart.shoppingCart));
                                        break;
                                    default:
                                        pathsToAdd.push(_object_spread({}, pdpShoppingCart.pdp));
                                        break;
                                }
                            });
                        }
                        return _object_spread_props(_object_spread({}, path), {
                            params: _object_spread_props(_object_spread({}, path.params), {
                                elements: _object_spread_props(_object_spread({}, path.params.elements), {
                                    productsAll: productsAllInCustomHubs
                                })
                            })
                        });
                    });
                    if ((pathsToAdd === null || pathsToAdd === void 0 ? void 0 : pathsToAdd.length) > 0) {
                        pathsToAdd.forEach(function(p) {
                            paths.push(p);
                        });
                    }
                    return _ctx.abrupt("return", paths.map(function(staticPages) {
                        return staticPages;
                    }));
                case 59:
                    _ctx.prev = 59;
                    _ctx.t1 = _ctx["catch"](1);
                    console.error(_ctx.t1);
                    throw new Error("Error get static props ".concat(_ctx.t1));
                case 63:
                    _ctx.prev = 63;
                    endTime = new Date().getTime();
                    console.info("## getStaticPathPages END ## timestamp: ".concat(endTime, "; executionTime: ").concat(endTime - startTime));
                    return _ctx.finish(63);
                case 67:
                case "end":
                    return _ctx.stop();
            }
        }, _callee, null, [
            [
                1,
                59,
                63,
                67
            ],
            [
                26,
                39,
                43,
                51
            ],
            [
                44,
                ,
                46,
                50
            ]
        ]);
    }));
    return function getStaticPathPages(appType, env) {
        return _ref.apply(this, arguments);
    };
}();
export { getStaticPathPages };
